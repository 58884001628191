import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'antd/es/grid';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { TIconNames } from 'components/Icon';
import { changeHeaderCollapsedState, setHeaderHeight } from 'redux/slices';
import useElementResize from 'hooks/useElementResize';
import { SSidebarExpandIcon } from 'components/Sidebar/Sidebar.style';
import SHeader from './Header.style';
import SidebarSwitcher from '../SidebarSwitcher/SidebarSwitcher';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import CreateButton from '../CreateButton/CreateButton';
import Gauges from '../Gauges/Gauges';
import GaugeMobileMenu from '../Gauges/components/GaugeMobileMenu';
import TimeTracker from '../TimeTracker/TimeTracker';
import HeaderAlert from '../HeaderAlert/HeaderAlert';
import Help from '../../Help/Help';

const Header = () => {
  const dispatch = useAppDispatch();
  const isHeaderCollapsed = useAppSelector(
    state => state.appSettings.header.collapsed,
  );
  const isAccessLimited = useAppSelector(
    state => state.paymentInfoSlice?.isAccessLimited,
  );
  const headerRef = useRef<HTMLDivElement>(null);
  const { height } = useElementResize({ ref: headerRef });
 
  useEffect(() => {
    dispatch(setHeaderHeight(height));
  }, [height]);

  if (isAccessLimited) {
    return (
      <div ref={headerRef}>
        <SHeader className="header">
          <Row justify="end" align="top">
            <ProfileMenu isAccessLimited />
          </Row>
        </SHeader>
      </div>
    );
  }
  return (
    <div ref={headerRef}>
      <HeaderAlert />
      <SHeader className="header">
        <Row justify="space-between" align="top" wrap={false}>
          <Col md={2} lg={0} className="mt-2">
            <SidebarSwitcher />
          </Col>
          <Col md={0} sm={0} xs={0} xl={24} xxl={24} lg={24} flex="6 1 6px">
            <Row wrap={false} align="top">
              <Col>
                <SSidebarExpandIcon
                  size={22}
                  icon={
                    isHeaderCollapsed
                      ? TIconNames.EXPAND_RIGHT
                      : TIconNames.COLLAPSE_LEFT
                  }
                  onClick={() =>
                    dispatch(changeHeaderCollapsedState(!isHeaderCollapsed))
                  }
                  className="pointer mt-1 header-expand-icon"
                />
              </Col>
              <Gauges />
            </Row>
          </Col>
          <Col flex="0 1 370px">
            <Row justify="end" align="middle" wrap={false}>
              <Col offset={1}>
                <TimeTracker />
              </Col>
              <Col offset={1} id="dashbar_create_button">
                <CreateButton />
              </Col>
              {/* <Col offset={1}>
              <Button
                width={32}
                type="primary"
                shape="circle"
                icon={<Icon icon={TIconNames.NOTIFICATION} size={15} />}
              />
            </Col> */}
              <Col offset={1}>
                <Help />
              </Col>
              <Col offset={1}>
                <ProfileMenu />
              </Col>
              <Col xs={1} sm={1} md={1} lg={0} xxl={0}>
                <GaugeMobileMenu />
              </Col>
            </Row>
          </Col>
        </Row>
      </SHeader>
    </div>
  );
};

export default Header;
