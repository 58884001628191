import { NotificationType } from 'components/Notification';
import { Colors } from 'core/CssVariables';
import { Moment } from 'moment';
import { TCategory, TCategoryUi } from './category.types';
import { TCompanyShortData, TCompanyShortDataUi } from './shortData.type';
import { WorkTypeEnum, WorkTypeEnumUi } from './workTypes.types';

export enum InvoiceProductSourceType {
  FREE = 'Free',
  CONTRACT_SERVICE = 'Contract service',
  CONTRACT_LICENSE = 'Contract License',
  TICKET_MATERIAL = 'Ticket Material',
  PROJECT_MATERIAL = 'Project Material',
  PROJECT_TIME_ENTRY = 'Project Time Entry',
  TIME_ENTRY = 'Time Entry',
}

export type TInvoiceProductItemUi = {
  rowId?: string;
  id?: string;
  sourceType?: InvoiceProductSourceType;
  sourceId?: string;
  name: string;
  unitPrice: number;
  count: number;
  price: number;
  category?: TCategoryUi;
  description?: string;
};
export type TInvoiceProductItem = {
  id?: string;
  sourceType?: InvoiceProductSourceType;
  sourceId?: string;
  name: string;
  unitPrice: number;
  count: number;
  price: number;
  category?: TCategory;
  description?: string;
};
export type TInvoiceProductItemPost = {
  id?: string;
  sourceType?: InvoiceProductSourceType;
  sourceId?: string;
  name: string;
  unitPrice: number;
  count: number;
  price: number;
  categoryId?: string;
  description?: string;
};

export type TInvoiceGetUi = {
  id: string;
  creationDateTime: string;
  company: TCompanyShortDataUi;
  name: string;
  range: string;
  dueDate: string;
  products: TInvoiceProductItemUi[];
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  status: EInvoiceStatusUi;
  invoiceDate: string;
  note?: string;
  actions: TInvoiceActionsUi;
  discount: number;
  subtotalAmount: string;
  totalAmount: string;
  asInvoiceNumber?: string;
};

export type TInvoiceGet = {
  id: string;
  creationDateTime: string;
  company: TCompanyShortData;
  name: string;
  startDate: string;
  endDate: string;
  dueDate: string;
  products: TInvoiceProductItem[];
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  status: EInvoiceStatus;
  asInvoiceNumber?: string;
  invoiceDate: string;
  note?: string;
  actions: EInvoiceActions[];
  discount: number | null;
  subtotalAmount?: number;
  totalAmount?: number;
};

export enum EBulkInvoiceType {
  BILLABLE_TIME = 'Billable Time',
  CONTRACT = 'Contract',
}

export type TBulkInvoicePostUi = {
  invoiceName: string;
  companyIds: string[];
  range: Moment[];
  type: EBulkInvoiceType;
  workTypes?: WorkTypeEnumUi[];
  contractTypeIds?: string[];
  includeUntypedContracts?: boolean;
  mergeByContracts?: boolean;
  invoiceDate: Moment;
};
export type TBulkInvoicePost = {
  invoiceName: string;
  companyIds: string[];
  startDate: string;
  endDate: string;
  type: EBulkInvoiceType;
  workTypes?: WorkTypeEnum[];
  contractTypeIds?: string[];
  includeUntypedContracts?: boolean;
  mergeByContracts?: boolean;
  invoiceDate: string;
};

export type TInvoicePostUi = {
  invoiceName: string;
  companyId: string;
  range: Moment[];
  invoiceDate: Moment;
  dueDate: Moment;
  products: TInvoiceProductItemUi[];
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  note?: string;
  discount: number;
};
export type TInvoicePost = {
  invoiceName: string;
  companyId: string;
  startDate: string;
  endDate: string;
  invoiceDate: string;
  dueDate: string;
  products: TInvoiceProductItemPost[];
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  note?: string;
  discount: number;
};

export enum InvoiceFilters {
  RANGE = 'range',
  COMPANY = 'companyIds',
  STATUS = 'statuses',
}
export enum InvoiceFilterNames {
  'Invoiced_period' = 'Invoiced period',
  'Company' = 'Company',
  'Status' = 'Status',
}

export type TInvoiceForm = {
  companyId: string;
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  invoiceName: string;
  range: Moment[];
  invoiceDate: Moment;
  dueDate: Moment;
  term: string;
  note?: string;
};

export enum EInvoiceStatusUi {
  Draft = 'Draft',
  Exported = 'Exported',
  'Partially Paid' = 'Partially Paid',
  Paid = 'Paid',
  Sent = 'Sent',
}
export enum EInvoiceStatus {
  Draft = 'Draft',
  Exported = 'Exported',
  'Partially Paid' = 'Partially Paid',
  Paid = 'Paid',
  Sent = 'Sent',
}
export enum EInvoiceActions {
  SAVE = 'SAVE',
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
  EXPORT_AND_SEND = 'EXPORT_AND_SEND',
}
export type TInvoiceActionsUi = {
  [key in EInvoiceActions]?: boolean;
};

export type TInvoiceStatusMapperColor = {
  [key in EInvoiceStatusUi]: Colors;
};

export type TInvoiceEditUi = {
  invoiceName: string;
  invoiceDate: Moment;
  dueDate: Moment;
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  products: TInvoiceProductItemUi[];
  note?: string;
  discount: number;
};
export type TInvoiceEdit = {
  invoiceName: string;
  invoiceDate: string;
  dueDate: string;
  phoneNumber?: string;
  address: string;
  emailAddress: string;
  products: TInvoiceProductItemPost[];
  note?: string;
  discount: number;
};

export type TMergedInvoicePost = {
  mergedInvoiceData: TInvoicePost;
  mergedInvoices: string[];
};
export type TMergedInvoicePostUi = {
  mergedInvoiceData: TInvoicePostUi;
  mergedInvoices: string[];
};
export type TMergedInvoiceTableDataUi = TInvoiceGetUi & {
  mainInvoice: boolean;
};

export type TExportInvoiceResponse = {
  msg: string;
  saved: boolean;
  exported: boolean;
  sent: boolean;
};
export type TExportInvoiceResponseUi = {
  msg: string;
  toastType: NotificationType;
};
