import React, {FC} from 'react';
import { useAppSelector } from 'redux/store';
import beacon from '../../assets/images/beacon.svg';
import Image from '../Image/Image';
import { Row } from '../Grid/Grid';

const HelpScoutBeacon: FC = () => {
  const user = useAppSelector(state => state.authSlice.user);

  const handleClickBeacon = () => {
    // @ts-ignore
    window.Beacon('toggle');
  };

  return (
    <>
      {user?.featureFlags.help_ghost && <div dangerouslySetInnerHTML={{ __html: '<helpghost-widget org-id="2a9bc869-bc01-4491-918f-23c310dcfea4" org-name="Zest MSP" inline-mode="false" primary-color=#ad5cab primary-text=#ffffff background-color=#ffffff background-text=#333333 user-message-color=#d6d6d6 user-message-text-color=#000000 helpghost-message-color=#464a7d helpghost-message-text-color=#ffffff/>' }} />}
      {!user?.featureFlags.help_ghost &&  <Row
        className="pointer"
        style={{
          backgroundColor: '#ac5cab',
          borderRadius: '50px',
          height: '32px',
          width: '32px',
        }}
        justify="center"
        align="middle"
      >
        <Image
          onClick={handleClickBeacon}
          src={beacon}
          alt="helpscout"
          width={20}
        />
      </Row>}
    </>
  );
};

export default HelpScoutBeacon;
